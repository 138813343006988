"use client";

import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
  type FunctionComponent,
} from "react";
import { useNavigator } from "~/app/hooks/useNavigator";
import { config } from "~/global.config";

type BackProps = { fallbackRoute?: string };

export interface HistoryContext {
  back: (props: BackProps) => void;
  entries: URL[];
}

const HistoryContext = createContext({} as HistoryContext);

HistoryContext.displayName = "History context";

export interface HistoryProviderProps {
  ignoredRouteFrags?: string[];
  children: ReactNode;
}

const HISTORY_LIMIT = 50;

const INGORED_ROUTE_FRAGMENTS = ["#", "/onboarding"];

export const HistoryProvider: FunctionComponent<HistoryProviderProps> = ({
  children,
}) => {
  const { navigateTo, url } = useNavigator();
  const [history] = useState<URL[]>([]);

  const back = useCallback(
    ({ fallbackRoute }: BackProps) => {
      while (history.length) {
        const current = new URL(url, config.origin);
        const past = history.pop();

        if (past.origin !== config.origin) {
          continue;
        }

        if (current.href === past.href) {
          continue;
        }

        const route = past.pathname + past.search;
        navigateTo(route);
        return;
      }

      if (!history.length) {
        navigateTo(fallbackRoute ?? "/");
      }
    },
    [history, navigateTo, url],
  );

  useEffect(() => {
    const ignore = INGORED_ROUTE_FRAGMENTS.find((frag) => url.includes(frag));

    if (ignore) {
      return;
    }

    while (history.length >= HISTORY_LIMIT) {
      history.shift();
    }

    history.push(new URL(url, config.origin));
  }, [history, url]);

  return (
    <HistoryContext.Provider
      value={{
        back,
        entries: history,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};

export function useHistory(): HistoryContext {
  const context = useContext(HistoryContext);
  return context;
}
